import React, { useState, useEffect } from 'react';
import './style.css';
import Button from '../Button';
import { StaticImage } from "gatsby-plugin-image";
import { inject, observer } from 'mobx-react';

const HomeSlider = observer(({ store }) => {
  const [anim, setAnim] = useState(true)
  useEffect(() => {
    if (store.sliderBoxLoaded) {
      setAnim(false)
    }
    store.setSliderBoxLoaded();
  }, [])

  return (
    <section>
      <div className="filler"></div>
      <div className="slider">
        <StaticImage
          placeholder='blurred'
          loading='lazy'
          className="slider-image"
          src='../../images/main-cover1.jpg'
          alt='MRI kabina enterijer'
        />
        <div className={`slider-box ${anim ? 'slider-box-animation' : ''}`}>
          <div className="slider-box-title">
            <h1>Rešenja za MRI Zaštitu</h1>
          </div>
          <div className="slider-box-text">
            <p>Kompanija Piperski D.O.O. se bavi proizvodnjom i ugradnjom RF zaštitnih soba. Naša specijalnost su sobe za magnetnu rezonancu.</p>
          </div>
          <div className="slider-box-buttons">
            <Button multiButton={true} link='/products' size='medium' text='Proizvodi' />
            <Button multiButton={true} link='/contact' size='medium' text='Kontakt' />
          </div>
        </div>
      </div>
    </section>
  );
})

export default inject('store')(HomeSlider);
