import React from 'react';
import '../style.css';

const FrameIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }}  version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 102.94 122.88">
        <g style={{ fill: `${fill}` }}><path d="M1.69,0.03h99.55c0.44-0.08,0.91,0.04,1.25,0.38c0.27,0.27,0.41,0.63,0.41,0.99v0v0l0,0.03v119.71 c0.1,0.44-0.02,0.93-0.36,1.28c-0.07,0.07-0.14,0.13-0.22,0.18c-0.23,0.17-0.52,0.27-0.83,0.27H1.42c-0.78,0-1.4-0.63-1.4-1.4V1.61 c-0.06-0.42,0.07-0.87,0.4-1.2C0.76,0.06,1.24-0.06,1.69,0.03L1.69,0.03z M90.58,109l9.52,9.13V4.8l-9.52,9.54V109L90.58,109z M98.08,120.07l-9.53-9.14H14.46l-9.55,9.14H98.08L98.08,120.07z M2.82,118.19l9.52-9.12V14.33L2.82,4.81V118.19L2.82,118.19z M88.44,12.51l9.65-9.68H4.82l9.67,9.68H88.44L88.44,12.51z M87.76,15.33h-72.6v92.77h72.6V15.33L87.76,15.33z" />
        </g></svg>
    </div>
  );
}

export default FrameIcon;
