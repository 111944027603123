import React from 'react';
import './style.css';
import Button from '../Button';

const ProductCard = ({ door, icons, image, imageAlt, buttonLink, title, text, link, buttonText }) => {
  return (
    <div className="product-card">
      <div className="product-card-inner">
        <div className="product-card-title">
          <p>{title}</p>
          <div style={{margin: '8px auto 9px auto'}} className="title-dash"/>
        </div>
        <div className="product-card-image-wrapper">
          <img style={door ? { width: '140px' } : {}} loading='lazy' src={image} alt={imageAlt} />
        </div>
        <div className="product-card-icons-wrapper">
          {icons}
        </div>
        <div className="product-card-text">
          <p>{text}</p>
        </div>
      </div>
      <div className="product-card-button">
        <Button link={buttonLink} size='medium' text={buttonText} />
      </div>
    </div>
  )
}

export default ProductCard;
