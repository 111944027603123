import React from 'react';
import '../style.css';

const MeshIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 118.96">
        <path style={{ fill: `${fill}` }} d="M3.27,0H119.61a3.26,3.26,0,0,1,3.27,3.27V115.69a3.26,3.26,0,0,1-3.27,3.27H3.27A3.26,3.26,0,0,1,0,115.69V3.27A3.26,3.26,0,0,1,3.27,0ZM116.34,81.49H84.1v30.93h32.24V81.49Zm0-37.48H84.1V75h32.24V44ZM84.1,6.54V37.47h32.24V6.54ZM77.56,81.49H45.32v30.93H77.56V81.49Zm0-37.48H45.32V75H77.56V44ZM45.32,6.54V37.47H77.56V6.54ZM38.78,81.49H6.54v30.93H38.78V81.49Zm0-37.48H6.54V75H38.78V44Zm0-37.47H6.54V37.47H38.78V6.54Z" />
      </svg>
    </div>
  );
}

export default MeshIcon;
