import React from 'react';
import '../CopperImageSection/style.css';
import { StaticImage } from "gatsby-plugin-image";
import './style.css';

const FillerImageSection = () => {
  return (
    <section className="filler-image-section">
      <StaticImage
        src='../../images/page-title-four.png'
        alt='Copper plates for MRI cabin'
        loading='lazy'
        placeholder='blurred'
        quality={100}
      />
      {/* <div className='container'>
        <div className="labels-wrapper">
          <div className="black-label">
            <p>16 godina iskustva</p>
          </div>
        </div>
      </div>
      <div className='filler-container-wrapper'>
        <div className='container'>
          <div className="red-label">
            <p>52 uspešna projekta</p>
          </div>
        </div>
      </div> */}

      <div className='container'>
        <div className="labels-wrapper">
          <div className="black-label">
            <p>18 godina iskustva</p>
          </div>
        </div>
      </div>
      <div className='filler-container-wrapper'>
        <div className='container'>
          <div className="red-label">
            <p>Preko 70 uspešnih projekata</p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FillerImageSection;
