import React from 'react';
import './style.css';
import StandardSectionWrapper from '../StandardSectionWrapper';
import CabinImage from '../../images/cabin-image.png';
import DoorImage from '../../images/door-image.png';
import WindowImage from '../../images/window-image.png';
import ProductCard from '../ProductCard';
import MeshIcon from '../Icons/MeshIcon';
import MeterIcon from '../Icons/MeterIcon';
import NoSoundIcon from '../Icons/NoSoundIcon';
import FrameIcon from '../Icons/FrameIcon';
import GlassWindowImg from '../../images/glass-window.png'
import ConstructionIcon from '../Icons/ConstructionIcon';
import CopperIcon from '../Icons/CopperIcon';
import InteriorIcon from '../Icons/InteriorIcon';

const doorText = 'Vrata su standardnih dimenzija 120x120cm. Izradjena su od prohromskog okvira i bakarnog tela sa membranom za RF i zvučnu izolaciju. Dihtunzi su oblozeni bakarnim žabicama.';
const windowText = 'Prozor se sastoji od dva komada stakla i bakarne mrežice sa prevlakom protiv refleksije. Prostor izmedju stakala pruza dodatnu RF i zvučnu zaštitu.';
const cabinText = 'Osnovna potkonstrukcija kabine je od drveta dok je RF izolacioni materijal bakarna folija. Sistem gradnje je takav da se lako prilagođava svakom obliku prostorije i pruža mogućnost izmena u poslednjem trenutku.';

const iconWidth = 25;

const doorIcons = (
  <>
    <div className="product-card-icon">
      <MeterIcon fill='#555' width={iconWidth} />
      <p className="product-card-icon-title">120x210cm</p>
    </div>
    <div className="product-card-icon">
      <FrameIcon fill='555' width={iconWidth} />
      <p className="product-card-icon-title">Prohromski okvir</p>
    </div>

    <div className="product-card-icon">
      <NoSoundIcon fill='#555' width={iconWidth} />
      <p className="product-card-icon-title">Zvučna izolacija</p>
    </div>

  </>
);

const windowIcons = (
  <>
    <div className="product-card-icon">
      <MeterIcon fill='#555' width={iconWidth} />
      <p className="product-card-icon-title">120x80cm, 180x180cm</p>
    </div>
    <div className="product-card-icon">
      <MeshIcon fill='#ce7c54' width={iconWidth} />
      <p className="product-card-icon-title">Bakarna mrežica</p>
    </div>
    <div className="product-card-icon">
      {/* <GlassIcon  width={22}   /> */}
      <img src={GlassWindowImg} alt='MRI prozor' style={{ width: '25px', marginRight: '8px', opacity: '0.7' }} />
      <p className="product-card-icon-title">Duplo staklo</p>
    </div>

  </>
);

const cabinIcons = (
  <>
    <div className="product-card-icon">
      <ConstructionIcon fill='#555' width={iconWidth} />
      <p className="product-card-icon-title">Konstrukcija cele kabine</p>
    </div>
    <div className="product-card-icon">
      <CopperIcon width={iconWidth} />
      <p className="product-card-icon-title">Bakarna folija</p>
    </div>
    <div className="product-card-icon">
      {/* <GlassIcon  width={22}   /> */}
      <InteriorIcon width={iconWidth} />
      <p className="product-card-icon-title">Enterijer</p>
    </div>

  </>
);

const ProductCards = () => {
  return (
    <section className="product-cards-container">
      <StandardSectionWrapper hasTitle={true} title="Proizvodi">
        <div className="product-cards-wrapper">
          <ProductCard
            title='RF Vrata'
            image={DoorImage}
            imageAlt='RF Vrata'
            text={doorText}
            buttonText='Saznaj više'
            buttonLink='/products/#productsdoor'
            icons={doorIcons}
            door
          />
          <ProductCard
            title='RF Prozor'
            icons={windowIcons}
            image={WindowImage}
            imageAlt='RF Prozor'
            text={windowText}
            buttonText='Saznaj više'
            buttonLink='/products/#productswindow'
          />
          <ProductCard
            title='RF Kabina'
            image={CabinImage}
            imageAlt='RF Kabina'
            icons={cabinIcons}
            text={cabinText}
            buttonText='Saznaj više'
            buttonLink='/products/#productscabin'
          />
          {/* <ProductCard
						title='Filteri i talasovodi'
						image={CabinImage}
						imageAlt='RF Kabina'
						text={text}
						buttonText='Saznaj više'
					/> */}
          <div className='stripe stripe-top' />
          <div className='stripe' />
          <div className='stripe stripe-bottom' />
        </div>
      </StandardSectionWrapper>
    </section>
  );
}

export default ProductCards;
