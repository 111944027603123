import React from 'react';
import '../style.css';
import './style.css';

const CopperIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }} id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 73.18">
        <path class="cls-1-co" d="M32.46,31.6,40.93,1.8A2.48,2.48,0,0,1,43.31,0h36.1a2.49,2.49,0,0,1,2.43,2l8.69,29.76a2.47,2.47,0,0,1-1.68,3.06,2.35,2.35,0,0,1-.69.1H34.79a2.47,2.47,0,0,1-2.47-2.47,2.11,2.11,0,0,1,.14-.85Z" />
        <path class="cls-1-co" d="M.14,69.87,8.61,40.08A2.48,2.48,0,0,1,11,38.27H47.1a2.48,2.48,0,0,1,2.43,2L58.22,70a2.47,2.47,0,0,1-1.68,3.06,2.35,2.35,0,0,1-.69.1H2.48A2.48,2.48,0,0,1,0,70.71a2.6,2.6,0,0,1,.14-.84Z" />
        <path class="cls-1-co" d="M64.71,69.87l8.45-29.79a2.48,2.48,0,0,1,2.38-1.81h36.11a2.49,2.49,0,0,1,2.43,2L122.78,70a2.47,2.47,0,0,1-1.68,3.06,2.35,2.35,0,0,1-.69.1H67a2.47,2.47,0,0,1-2.47-2.47,2.15,2.15,0,0,1,.14-.84Z" />
        <path class="cls-2-co" d="M32.4,31.67l8.47-29.8A2.48,2.48,0,0,1,43.25.06h36.1a2.49,2.49,0,0,1,2.43,2l.14.47H47a2.48,2.48,0,0,0-2.38,1.81l-8.47,29.8h0A2.3,2.3,0,0,0,36,35v0H34.75a2.47,2.47,0,0,1-2.47-2.47,1.77,1.77,0,0,1,.12-.84Z" />
        <path class="cls-2-co" d="M.14,69.87,8.61,40.08A2.48,2.48,0,0,1,11,38.28H47.1a2.49,2.49,0,0,1,2.43,2l.14.46H14.77a2.49,2.49,0,0,0-2.38,1.8L3.92,72.32h0a2.3,2.3,0,0,0-.14.83v0H2.47a2.48,2.48,0,0,1-2.33-3.3Z" />
        <path class="cls-2-co" d="M64.71,69.87l8.46-29.79a2.48,2.48,0,0,1,2.38-1.8h36.11a2.48,2.48,0,0,1,2.43,2l.14.46H79.31a2.49,2.49,0,0,0-2.38,1.8L68.47,72.32h0a2.3,2.3,0,0,0-.14.83v0H67a2.47,2.47,0,0,1-2.47-2.47,2.07,2.07,0,0,1,.14-.83Z" />
      </svg>
    </div>
  );
}

export default CopperIcon;
