import React from 'react';
import './style.css';
import Button from '../Button';
import { StaticImage } from "gatsby-plugin-image";

const CopperImageSection = () => {
  return (
    <section className="image-section">
      <StaticImage
        src='../../images/copper-plates.png'
        alt='Copper plates for MRI cabin'
        loading='lazy'
        placeholder='blurred'
        quality={100}
      />
      <div className='container quote-wrapper'>
        <div className="home-quote">
          <div className="home-quote-title">
            <h4>Kontaktirajte nas za procenu<br className="line-break" /> za vaš projekat</h4>
          </div>
          <div className="">
            <Button link='/contact' size='medium' text='Kontakt' />
          </div>
        </div>
      </div>
    </section>
  );
}

export default CopperImageSection;
