import React from 'react';
import '../style.css';
import './style.css';

const NoSoundIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }}  id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88">
        <path class="ns-cls-1" d="M21.2,32.93,90,101.68A49.29,49.29,0,0,1,12.13,61.44h0A49.12,49.12,0,0,1,21.2,32.93ZM98.92,93.5,29.39,24A49.31,49.31,0,0,1,98.92,93.5Z" />
        <path style={{fill: '#666'}} d="M34.13,47.19H48.38L60.51,35a1.93,1.93,0,0,1,2.72,0,1.88,1.88,0,0,1,.57,1.36h0V86.53a1.93,1.93,0,0,1-3.31,1.35l-12-10.13H34.13A6.16,6.16,0,0,1,28,71.61V53.34a6.16,6.16,0,0,1,6.15-6.15Z" />
        <path style={{ stroke: `${fill}` }} class="ns-cls-2" d="M90.47,44.33c5.53,11.48,6.16,22.9.3,34.22M81.52,50.14c3.16,7.57,3.43,15.1.4,22.6M72.25,57a11.38,11.38,0,0,1-.24,8.9" />
        <path class="ns-cls-3" d="M61.44,0A61.31,61.31,0,1,0,84.93,4.66,61.29,61.29,0,0,0,61.44,0ZM21.2,32.93,90,101.68A49.44,49.44,0,0,1,42.57,107,49.53,49.53,0,0,1,15.86,80.3a49,49,0,0,1-3.73-18.86h0A48.93,48.93,0,0,1,21.2,32.93ZM98.92,93.5,29.38,24A49.32,49.32,0,0,1,98.92,93.5Z" />
      </svg>
    </div>
  );
}

export default NoSoundIcon;
