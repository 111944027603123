import React from 'react';
import '../style.css';

const MeterIcon = ({ fill, width, marginBottom }) => {
  return (
    <div style={{ marginBottom: `${marginBottom}px` }} className="icon-wrapper">
      <svg style={{ width: `${width}px` }} version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 122.88 121.55">
        <g style={{ fill: `${fill}` }}><path d="M98.31,101.27l-5.44,5.49c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l5.43-5.48l-5.2-5.14l-3.37,3.4 c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l3.36-3.39l-5.2-5.14l-5.33,5.39c-1.12,1.13-2.95,1.13-4.07,0.01 s-1.13-2.95-0.01-4.07l5.32-5.37l-5.2-5.14l-3.11,3.14c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l3.1-3.13 l-5.2-5.14l-5.23,5.28c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l5.21-5.27l-5.2-5.14l-2.95,2.98 c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l2.94-2.97l-5.2-5.14l-5.12,5.17c-1.12,1.13-2.95,1.13-4.07,0.01 c-1.13-1.12-1.13-2.95-0.01-4.07l5.11-5.16l-5.2-5.14L30.6,39.6c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07 l2.62-2.65l-5.4-5.34l-5.61,5.67c-1.12,1.13-2.95,1.13-4.07,0.01c-1.13-1.12-1.13-2.95-0.01-4.07l5.6-5.66L5.78,9.8v105.98h107.2 L98.31,101.27L98.31,101.27z M5.07,0.99l29.87,29.53c0.11,0.08,0.22,0.18,0.33,0.28c0.1,0.1,0.19,0.2,0.27,0.31l86.48,85.5 c1.13,1.12,1.15,2.94,0.03,4.07c-0.56,0.57-1.31,0.86-2.05,0.86v0.01H2.89c-1.6,0-2.89-1.29-2.89-2.89V2.89C0,1.29,1.29,0,2.89,0 C3.76,0,4.54,0.38,5.07,0.99L5.07,0.99z M28.35,62l31.88,31.76c1.13,1.12,1.13,2.95,0.01,4.07c-0.56,0.57-1.3,0.85-2.04,0.85v0.01 H26.16c-1.6,0-2.89-1.29-2.89-2.89V63.89c0-1.6,1.29-2.89,2.89-2.89C27.04,61,27.82,61.39,28.35,62L28.35,62z M51.22,92.91 L29.05,70.83v22.08H51.22L51.22,92.91z" />
        </g></svg>
    </div>
  );
}

export default MeterIcon;
