import React from 'react';
import StandardSectionWrapper from '../StandardSectionWrapper';
import './style.css';
import InfoImage from '../../images/info-image.png';
import Button from '../Button';

const InfoSection = () => {
  return (
    <StandardSectionWrapper hasBorder>
      <div className="info-section-wrapper">
        <div className="info-box">
          <div className="info-title">
            <h3>O nama</h3>
            <div style={{marginTop: '8px'}} className="title-dash" />
          </div>
          <div className="info-text">
            <p>
              Sa sedištem u Beogradu, Piperski D.O.O. je kompanija 
              koja pruža rešenja za RF zaštitu od 2004.
              Naša specijalnost su kompletno opremljene sobe za magnetnu rezonancu.
              Takođe se bavimo i servisiranjem i izradom pojedinačnih delova kao što su vrata, prozor, filteri, talasovodi i drugo.
              Svi proizvodi se izrađuju po specifikacijama proizvođača magnetne rezonance.
            </p>
          </div>
          <Button link='/about' size='medium' text='O nama' />
        </div>
        <div className="info-image ">
          <img className="slide-from-right" src={InfoImage} alt='MRI soba ilustracija' loading='lazy' />
        </div>
      </div>
    </StandardSectionWrapper>
  )
}

export default InfoSection;
