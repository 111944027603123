import React, { useEffect, useState } from 'react';
import Layout from "../components/layout";
import CopperImageSection from "../components/CopperImageSection";
import FillerImageSection from "../components/FillerImageSection";
import ProductCards from "../components/ProductCards";
import HomeSlider from "../components/HomeSlider";
import SmallIconBoxesSection from '../components/SmallIconBoxesSection'
import SimpleMap from '../components/SimpleMap';
import Button from '../components/Button';
import InfoSection from "../components/InfoSection";
import Seo from '../components/seo';
import { inject, observer } from "mobx-react";
// UA-213886539-1
const IndexPage = (observer(({ store }) => {
  const [pageLoaded, setPageLoaded] = useState(false);
  useEffect(() => {
    store.closeMenu();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setPageLoaded(true)
    }, 1400)
  }, [])

  return (
    <Layout>
      <Seo title='Početna' description='Kompanija Piperski D.O.O. je specijalizovana za proizvodnju i ugradnju MRI soba, pojedinačnih komponenti za MRI i RF zaštitu i servisiranje MRI kabina.' />
      <article>
        <HomeSlider />
        <InfoSection />
        <SmallIconBoxesSection />
        <FillerImageSection />
        <ProductCards />
        <section className="projects-showcase">
          <div className="projects-showcase-title">
            <h2>Projekti</h2>
            <div className="title-dash" />
          </div>
          <div className="projects-showcase-expl">
            <p>Od 2004. godine smo realizovali preko 70 poslova u 11 država sveta i 30 gradova.</p>
            <p
              style={{ marginTop: '28px' }}
              className="mri-intro"
            >
              Licencirani smo za rad sa najvećim MRI proizvođačima:
            </p>
            <p className="mri-manufacturers">Phillips,  GE,  Neusoft,  Canon,  Siemens</p>

            <p style={{ marginTop: '23px' }}>(kliknite na pin na mapi za više informacija o poslovima na toj lokaciji)</p>
          </div>
          {pageLoaded ? <SimpleMap /> : <div style={{ height: '220px', flexDirection: 'column' }} className="loader-wrapper"> <div style={{ width: '61px', height: '61px' }} class="loader"></div><p style={{ marginTop: '20px' }}>Mapa se učitava</p></div>}
          <div className="projects-showcase-expl">
            <p>Više detalja o projektima i referentnu listu možete videti na stranici Projekti</p>
          </div>
          <Button link='/projects' size='medium' text='Projekti' />
        </section>
        <CopperImageSection />
      </article>
    </Layout>
  );
}));

export default inject('store')(IndexPage);
